/* App.css */

/* Styles for the App component */
.App {
  text-align: center;
  position: relative;
}

html {
  scroll-behavior: smooth;
}

.App-logo {
  height: 150px; /* Reduced height */
  pointer-events: none;
  /* Remove position: absolute */
}

/* Styles for the header */
.App-header {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(5px + 2vmin);
  color: rgb(15, 15, 15);
}

.Sub-header {
  background-color: #3498db;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(5px + 2vmin);
  text-align: left; /* Align the content to the left */
  padding-left: 20px;
  font-family: 'Roboto', sans-serif;
  color: rgb(5, 5, 5);
}

.header-content {
  display: flex;
  flex-direction: column;
  width: auto; /* Set the width to 50% */
  text-align: left; /* Align the content to the left */
  padding-left: 20px;
  font-family: 'Roboto', sans-serif;
}

.Sub-header-content {
  display: flex;
  flex-direction: column;
  width: auto; /* Set the width to 50% */
  text-align: left; /* Align the content to the left */
  padding-left: 20px;
  font-size: 35px;
  font-family: 'Roboto', sans-serif;
}
.navbar {
  /* commenting BG color to match the logo scheme
  background-color: #333;  Example color */
  height: 150px; /* Set the same height as the logo */
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.navbar-logo {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Add margin to separate the logo from the links */
}

.logo-text {
  margin-left: 5px; /* Add some space between the logo and text */
}

.navbar-links {
  list-style-type: none;
  display: flex; /* Make the list items horizontal */
  margin: 0;
  padding: 0;
  justify-content: flex-start; /* Align links to the start */
}

.navbar-links li {
  margin-right: 20px; /* Add spacing between navigation links */
}

.navbar-links li a {
  color: #1E72B8;
  text-decoration: none;
  font-size: 20px;
}

.company-name {
  list-style-type: none;
  display: flex; /* Make the list items horizontal */
  margin: 0;
  padding: 0 0 0 20px;
  justify-content: flex-start; /* Align links to the start */
}

.company-name li {
  margin-right: 20px; /* Add spacing between navigation links */
}

.company-name li a {
  color: #1E72B8;
  text-decoration: none;
  font-size: 30px;
}
/* Styles for the contact form */
.Contact-section {
  background-color: #f1f1f1; /* Grey background color */
  padding: 50px;
  text-align: left;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Ensure form elements are left-aligned */
}

.input-group {
  display: flex;
  align-items: center; /* Align items in the center vertically */
  margin-bottom: 20px;
  width: 100%;
  max-width: 500px; /* Adjust max-width as needed */
}

.contact-form label {
  margin-right: 10px; /* Space between label and input */
  font-weight: bold;
  width: 100px; /* Set a fixed width for labels */
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1; /* Input takes the remaining space */
  width: calc(100% - 120px); /* Adjust width to fit within the max-width */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.contact-form textarea {
  resize: vertical; /* Allow vertical resizing of textarea */
}

.contact-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: white;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  max-width: 500px;
}

.contact-form button:hover {
  background-color: #2980b9;
}



/* Styles for the certificates section */
.Certificates-section {
  text-align: center; /* Center-align text */
}

.Certificates-section .carousel {
  width: 80%; /* Set a fixed width for the carousel container */
  margin: 0 auto; /* Center the carousel */
  overflow: hidden; /* Hide overflow to prevent horizontal scrolling */
}

.Certificates-section .carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure each slide takes full height */
}

.Certificates-section .carousel .slide img {
  max-width: 80%; /* Ensure images don't exceed container width */
  max-height: 800px; /* Preserve aspect ratio */
  display: block;
  transition: transform 0.5s ease; /* Add smooth transition for size change */
  margin: 0; /* Remove any margin around the images */
  padding: 0; /* Remove any padding around the images */
}

.Certificates-section .carousel .slide img.center {
  transform: scale(1.2); /* Make the center image larger */
}

.Certificates-section .carousel .slide img:not(.center) {
  transform: scale(0.8); /* Make other images smaller */
  margin-right: -5px; /* Reduce spacing between images */
}

/*Maps Section*/
.Map-section h2 {
  margin-bottom: 20px;
  margin-left: 20px;
}

.map-container {
  margin-top: 20px;
}

.map-link {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 20px;
}

.map-icon {
  margin-right: 10px;
}


/*Whatsapp Section*/
.Whatsapp-section {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.whatsapp-text {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Whatsapp-section a {
  text-decoration: none;
  color: inherit;
}


/*Social icons*/
.Social-section {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.social-text {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Social-section a {
  text-decoration: none;
  color: inherit;
}

/*Map icons*/
.Map-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  text-align: center;
}

.map-text {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.Map-section a {
  text-decoration: none;
  color: inherit;
}

.catalog-button {
  background-color: hsl(192, 44%, 43%); /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 0 0 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.catalog-button:hover {
  background-color: #45a049;
}