/* Highlights.css */
.Highlights-section {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f5f5f5;
    margin: 20px;
  }
  
  .highlight {
    background-color: #808080; /* Grey background */
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 250px; /* Ensure uniform width */
  }
  
  .highlight img {
    width: 100%;
    height: 200px; /* Fixed height */
    object-fit: cover; /* Ensure the image covers the area */
    border-radius: 15px; /* Make the image corners rounded */
  }
  
  .highlight h3 {
    margin: 10px 0;
    font-size: 1.5em;
    color: white; /* Adjust text color for contrast */
  }
  
  .highlight p {
    font-size: 1em;
    color: white; /* Adjust text color for contrast */
  }
  